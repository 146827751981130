"use client"

import { useEffect, useState } from "react"
import localFont from "next/font/local"
import { useSearchParams } from "next/navigation"
import { QueryClient, QueryCache, QueryClientProvider } from "react-query"
import { Provider as JotaiProvider } from "jotai"
import { decrypt3DES } from "/lib/encryption"
import "./globals.css"

import "@fortawesome/fontawesome-svg-core/styles.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleNotch } from "@fortawesome/pro-solid-svg-icons"
import { config } from "@fortawesome/fontawesome-svg-core"
import { set } from "date-fns"
config.autoAddCss = false

// Web client portal
const clientportal = localFont({
  src: [
    {
      path: "./fonts/proximanova-regular.woff2.woff",
      weight: "400",
      style: "normal"
    },
    {
      path: "./fonts/proximanova-medium.woff2.woff",
      weight: "500",
      style: "medium"
    },
    {
      path: "./fonts/proximanova-semibold.woff2.woff",
      weight: "600",
      style: "semibold"
    },
    {
      path: "./fonts/proximanova-bold.woff2.woff",
      weight: "700",
      style: "bold"
    }
  ]
})

// iPhone
const itws = localFont({
  src: [
    {
      path: "./fonts/AvenirNext-Regular.woff2.woff",
      weight: "400",
      style: "normal"
    },
    {
      path: "./fonts/AvenirNext-Italic.woff2.woff",
      weight: "400",
      style: "italic"
    },
    {
      path: "./fonts/AvenirNext-Medium.woff2.woff",
      weight: "500",
      style: "medium"
    },
    {
      path: "./fonts/AvenirNext-MediumItalic.woff2.woff",
      weight: "500",
      style: "italic"
    },
    {
      path: "./fonts/AvenirNext-DemiBold.woff2.woff",
      weight: "600",
      style: "semibold"
    },
    {
      path: "./fonts/AvenirNext-DemiBoldItalic.woff2.woff",
      weight: "600",
      style: "italic"
    },
    {
      path: "./fonts/AvenirNext-Bold.woff2.woff",
      weight: "700",
      style: "bold"
    },
    {
      path: "./fonts/AvenirNext-BoldItalic.woff2.woff",
      weight: "700",
      style: "italic"
    },
    {
      path: "./fonts/AvenirNext-Heavy.woff2.woff",
      weight: "800",
      style: "heavy"
    },
    {
      path: "./fonts/AvenirNext-HeavyItalic.woff2.woff",
      weight: "800",
      style: "italic"
    }
  ]
})

// Android
const atws = localFont({
  src: [
    {
      path: "./fonts/roboto-light-webfont.woff2.woff",
      weight: "400",
      style: "light"
    },
    {
      path: "./fonts/roboto-lightitalic-webfont.woff2.woff",
      weight: "400",
      style: "italic"
    },
    {
      path: "./fonts/roboto-regular-webfont.woff2.woff",
      weight: "500",
      style: "normal"
    },
    {
      path: "./fonts/roboto-italic-webfont.woff2.woff",
      weight: "500",
      style: "italic"
    },
    {
      path: "./fonts/roboto-medium-webfont.woff2.woff",
      weight: "600",
      style: "medium"
    },
    {
      path: "./fonts/roboto-mediumitalic-webfont.woff2.woff",
      weight: "600",
      style: "italic"
    },
    {
      path: "./fonts/roboto-bold-webfont.woff2.woff",
      weight: "700",
      style: "semibold"
    },
    {
      path: "./fonts/roboto-bolditalic-webfont.woff2.woff",
      weight: "700",
      style: "italic"
    },
    {
      path: "./fonts/roboto-black-webfont.woff2.woff",
      weight: "800",
      style: "bold"
    },
    {
      path: "./fonts/roboto-blackitalic-webfont.woff2.woff",
      weight: "800",
      style: "italic"
    }
  ]
})

// Native Trader Workstation
const ntws = localFont({
  src: [
    {
      path: "./fonts/SourceSans3-Regular.woff2",
      weight: "500",
      style: "light"
    },
    {
      path: "./fonts/SourceSans3-Semibold.woff2",
      weight: "600",
      style: "normal"
    },
    {
      path: "./fonts/SourceSans3-Bold.woff2",
      weight: "700",
      style: "semibold"
    },
    {
      path: "./fonts/SourceSans3-Black.woff2",
      weight: "800",
      style: "bold"
    }
  ]
})

// Desktop
const dtws = localFont({
  src: [
    {
      path: "./fonts/Verdana.ttf",
      weight: "400",
      style: "normal"
    },
    {
      path: "./fonts/Verdana Italic.ttf",
      weight: "400",
      style: "italic"
    },
    {
      path: "./fonts/Verdana Bold.ttf",
      weight: "700",
      style: "bold"
    },
    {
      path: "./fonts/Verdana Bold Italic.ttf",
      weight: "700",
      style: "italic"
    }
  ]
})

const fonts = {
  web: clientportal,
  ios: itws,
  android: atws,
  desktop: dtws
}

export default function RootLayout({ children }) {
  const rawParams = useSearchParams()
  const rawParamsString = decodeURIComponent(rawParams.toString())
  let encodedParams = []
  if (rawParamsString) {
    const splitParams = rawParamsString.split("&")
    for (let i = 0; i < splitParams.length; i++) {
      let param = splitParams[i].split("=")
      encodedParams.push(param[0] + "=" + encodeURIComponent(param[1]))
    }
  }
  const params = new URLSearchParams(encodedParams.join("&"))

  const [fontSize, setFontSize] = useState(16)
  const [theme, setTheme] = useState("light")
  const [font, setFont] = useState(fonts["web"])
  const [readFontSize, setReadFontSize] = useState(false)
  const [readTheme, setReadTheme] = useState(false)
  const [readFont, setReadFont] = useState(false)

  useEffect(() => {
    async function asyncParseParams() {
      // Decode handshake and set variables
      if (params.has("handshake")) {
        let urlQueryParamString = await decrypt3DES(params.get("handshake"))
        let urlQueryParams = new URLSearchParams(urlQueryParamString)
        if (urlQueryParams.has("fontSize")) {
          setFontSize(urlQueryParams.get("fontSize"))
        }
        if (urlQueryParams.has("theme")) {
          setTheme(urlQueryParams.get("theme"))
        }
        if (urlQueryParams.has("ibPlatform")) {
          setFont(fonts[urlQueryParams.get("ibPlatform")])
        }
      }

      // If no handshake present then get variables from URL
      else {
        if (params.has("fontSize")) {
          setFontSize(params.get("fontSize"))
        }
        if (params.has("theme")) {
          setTheme(params.get("theme"))
        }
        if (params.has("ibPlatform")) {
          setFont(fonts[params.get("ibPlatform")])
        }
      }

      setReadFontSize(true)
      setReadTheme(true)
      setReadFont(true)
    }
    asyncParseParams()
  }, [params])

  const [queryClient] = useState(
    () =>
      new QueryClient({
        queryCache: new QueryCache({
          onError: (error, _query) => {
            // Window.postMessage the error
          }
        })
      })
  )

  if (readFontSize && readTheme && readFont) {
    return (
      <html
        lang="en"
        className={
          font?.className +
          " " +
          theme +
          " scrollbar-thumb-zinc-400 scrollbar-track-transparent scrollbar-corner-transparent hover:scrollbar-thumb-zinc-400 hover:scrollbar-track-transparent hover:scrollbar-corner-transparent active:scrollbar-thumb-zinc-400 active:scrollbar-track-transparent active:scrollbar-corner-transparent scrollbar-thumb-rounded-full scrollbar-track-rounded-full"
        }
        style={{
          fontSize: `${fontSize}px`
        }}
      >
        <body>
          <QueryClientProvider client={queryClient}>
            <JotaiProvider>{children}</JotaiProvider>
          </QueryClientProvider>
        </body>
      </html>
    )
  } else {
    return (
      <html lang="en">
        <body>
          <div className="w-screen h-screen flex items-center justify-center">
            <FontAwesomeIcon
              icon={faCircleNotch}
              className="spinner text-2xl text-slate-400"
              aria-hidden="true"
            />
          </div>
        </body>
      </html>
    )
  }
}
